<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>ข้อมูลโดยรวม</b-card-title>
      <b-card-text class="mr-25 mb-0">
        <!-- Updated 1 month ago -->
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in _statisticsItems"
          :key="item.icon"
          md="2"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  computed: {
    ...mapState('dashboardDataStore', ['results']),
    _statisticsItems() {
      const results = this.results
      return [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: this.withCommas(results?.saleTotal ?? 0),
          subtitle: 'ยอดขายรวมทุกเดือน',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-info',
          title: this.withCommas(results?.countClient ?? 0, false),
          subtitle: 'ผู้ใช้งานทั้งหมด',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon1',
          color: 'light-primary',
          title: this.withCommas(results?.countClientRegister24h ?? 0, false),
          subtitle: 'ผู้ใช้ใหม่ 24 ชม.',
          customClass: '',
        },
        {
          icon: 'UserIcon2',
          color: 'light-success',
          title: this.withCommas(results?.countPartner ?? 0, false),
          subtitle: 'ครีเอเตอร์ ทั้งหมด',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ImageIcon',
          color: 'light-danger',
          title: this.withCommas(results?.totalTopicImage ?? 0, false),
          subtitle: 'จำนวนรูปทั้งหมด',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'ImageIcon2',
          color: 'light-success',
          title: this.withCommas(results?.totalTopicZodiac ?? 0, false),
          subtitle: 'จำนวนราศีทั้งหมด',
          customClass: '',
        },
      ]
    },
  },
}
</script>
